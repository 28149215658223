<template>
	<div class="page__header" :class="{'is-hide': isSearchActive, 'hide-shadow': hideShadow }">
		<div class="page__header_container">
			<div class="back">
				<a @click="backToPreviousPage" v-if="!isNotURL">
					<span class="icon-arrow-left-2-filled" />
				</a>
				<a @click="onClickedBack"  v-else>
					<span class="icon-arrow-left-2-filled" @click="onClickedBack"/>
				</a>
			</div>
			<div class="title " :class="{'is-centered' : isCenteredTitle}" v-if="!isCategorySelected">
				<i v-if="isShowIcon" class="icon-thunder title-icon"></i>
				{{ titlePage }}
				<slot></slot>
			</div>
			<div class="title with-category" v-else @click="showCategoryPopUp">
				<div class="label-tag">
					{{$t('SearchResult.category')}} <span class="icon-arrow-down-2-filled" />
				</div>
				<h1 class="label-name" :class="{'owf' : pageType == 'owf'}">
					{{ titlePage }}
				</h1>
			</div>
			<span class="search" @click="focusSearch" v-if="!isHideSearch">
				<span class="imgs-menu imgs-menu--search magnifier-icon"></span>
			</span>
		</div>
	</div>
</template>

<script>
import SearchBar from '~/components/header/searchBar/index.vue';

export default {
	name: "StoreHeader",
	props: ['titlePage', 'backURL', 'isCategorySelected', 'isHideSearch', 'isShowIcon' ,'isCenteredTitle', 'onClick', 'isNotURL' ,'onClickCategory', 'hideShadow','pageType'],
	components: {
		SearchBar
	},
	data() {
		return {
			lang: this.$cookies.get('BS_PreferredLang') || this.$cookies.get('BS_translation')
		}
	},
	computed: {
		isSearchActive() {
			return this.$store.getters.getIsSearchActive;
		},
		previousPage() {
			return this.$store.getters.getPreviousLink;
		},
		isFromDifferentSource() {
			return this.$store.getters.getIsFromDifferentSource;
		}
	},
	methods: {
		focusSearch() {
			this.$store.dispatch('toggleSearchBar');
		},
		showCategoryPopUp() {
			this.$emit('onClickCategory')
		},
		onClickedBack() {
			this.$emit('onClick')
		},
		backToPreviousPage() {
			if (this.isFromDifferentSource) {
				this.$nuxt.$router.push({ path: this.localeLink('store') })
			} else {
				this.$router.back();
			}
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />