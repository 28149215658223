<template>
	<div class="image-slider">
		<div v-swiper:mySwiper="swiperOption">
			<div class="swiper-wrapper" v-if="images.length < 3">
				<div class="swiper-slide one-slide" v-for="(image, i) in images" :key="i">
					<a :href="image.targetHref" @click="trackBanner(image.title)">
						<img
							:src="getImageUrl(image.imageUrl, widthImg, heightImg, 80).imgSrc"
							:alt="getImageName(image.imageUrl)"
							:title="getImageName(image.imageUrl)"
							:sliderTitle="image.title"
							:sliderId="image.id"
						/>
					</a>
				</div>
			</div>
			<div class="swiper-wrapper" v-else>
				<div :style="{width: $device.isDesktop ? '40% !important' : ''}" class="swiper-slide" v-for="(image, i) in images" :key="i">
					<a :href="image.targetHref" @click="trackBanner(image.title)">
						<img
							:src="getImageUrl(image.imageUrl, widthImg, heightImg, 80).imgSrc"
							:alt="getImageName(image.imageUrl)"
							:title="getImageName(image.imageUrl)"
							:sliderTitle="image.title"
							:sliderId="image.id"
						/>
					</a>
				</div>
			</div>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
			<div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
		</div>
	</div>
</template>

<script>
const imageStyle = () => import("@/components/decorators/imageStyle.vue");

import { getImageName, calculateHeight } from "@/helpers/stringHelper";
import imageHelper from "@/helpers/imageHelper";

import '~/plugins/swiper';
 
export default {
	name: "banner",
	props: ["images"],
	components: {
		"image-style": imageStyle
	},
	mixins: [imageHelper],
	watch: {
		heightImg: function(newVal, oldVal) {
			this.heightImg = newVal
		}
	},
	data() {
		// this.images.push({
		// 	title: 'dsadsa',
		// 	imageUrl: 'https://alexandra.bridestory.com/image/upload/assets/promo-sept_platform-banner-1200x400-SkoBAdwrw.jpg'
		// })
		// this.images.push({
		// 	title: 'dsadsa',
		// 	imageUrl: 'https://alexandra.bridestory.com/image/upload/assets/biar-cinta-menjaga-flexi-reschedule_homepage-banner-1200x400-r1fww3VNv.jpg'
		// })
		return {
			swiperOption: {
				lazy: {
					loadPrevNext: false
				},
				autoplay: false,
				loop: true,
				spaceBetween: 20,
				slidesPerView: 1,
				breakpointsInverse: true,
				breakpoints: {
					1023: {
						centeredSlides: true,
						// slidesPerView: 3,
						slidesPerView: this.images.length > 2 ? 3 : 1,
						navigation: {
							nextEl: ".swiper-button-next",
							prevEl: ".swiper-button-prev"
						}
					}
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
					dynamicBullets: true
				},
				on: {
					click:	function(d) {
						let slider = d.target.attributes

						if (slider.sliderTitle && slider.sliderId) {
							const sliderTitle = slider.sliderTitle && slider.sliderTitle.nodeValue
							const sliderId = slider.sliderId && slider.sliderId.nodeValue

							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								event: 'v2_banner_click',
								v2_bannerId: sliderTitle + ';' + sliderId
							});
						}
					}
				}
			},
			widthImg: 762,
			heightImg: 254,

		}
	},
	mounted() {
		this.$nextTick(() => {
			//handle bug in swiper, when use lazy + component
			let currentSlide = this.mySwiper.activeIndex;
			let self = this;
			setTimeout(() => {
				if(this.mySwiper){
					self.mySwiper.slideTo(currentSlide);
					if (self.mySwiper.el) {
						self.mySwiper.el.style.opacity = 1
					}
				}
			}, 500);
		})

		window.addEventListener("resize", this.getPerfectSize);
		window.addEventListener("orientationchange", this.getPerfectSizes);
	},
	destroyed() {
		window.removeEventListener("resize", this.getPerfectSize);
		window.removeEventListener("orientationchange", this.getPerfectSizes);
	},
	methods: {
		getPerfectSize() {
			if (window.innerWidth > 768) {
				if(this.images.length > 2) {
					this.widthImg = window.innerWidth / 2.5;
					this.heightImg = calculateHeight(762, 254, this.widthImg);
				}
				this.swiperOption.lazy['loadPrevNext'] = true;
			} else {
				this.widthImg = window.innerWidth;
				this.heightImg = calculateHeight(762, 254, this.widthImg);
				this.swiperOption.lazy['loadPrevNext'] = false;
			}
		},
		trackBanner(title) {
			let category = 'store_home';
			if(this.storeType) category = 'store_' + this.storeType;
			dataLayer.push({
				store: {
					category: category,
					action: 'click_banner',
					label: title
				}
			})
		},
		getImageName(name) {
			return getImageName(name);
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />