import AdsAPI from "@/api/ads.js"
import Cookie from "js-cookie"

export default {
	name: "ImpressionHelper",
	computed: {
		account() {
			return this.$store.state.account.account
		},
		actorId() {
			return (
				(this.account &&
					this.account.account.data &&
					this.account.account.data.uuid) ||
				Cookie.get("BSUUID")
			)
		}
	},
	methods: {
		postImpression(adsId, impressionMeta, page, section) {
			let meta = { version: 2 }
			if (page) {
				meta = { ...meta, ...{ page: parseInt(page) } }
			}
			if (section) {
				meta = { ...meta, ...{ section: section } }
			}

			if (adsId && adsId.length) {
				let AdsApi = new AdsAPI(this.$axios)
				let data = {
					tracking_ids: [adsId] || null,
					actor_id: this.actorId,
					client_id: Cookie.get("BSUUID"),
					impression_meta: { ...impressionMeta, ...meta }
				}

				const el = document.querySelector(`[data-adsid="${adsId}"]`)
				if (el) {
					el.removeAttribute("data-adsid")
					if (page) {
						el.removeAttribute("data-ads-page")
					}
					if (section) {
						el.removeAttribute("data-ads-section")
					}
				}
				return AdsApi.sendImpression(data).catch(err => {
					console.error(err)
				})
			}
		},
		observeList({
			containerElementId,
			listElementClass,
			threshold = 0,
			impressionMeta
		}) {
			const list = document
				.getElementById(containerElementId)
				.querySelectorAll(listElementClass)

			let observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					const adsId = entry.target.dataset.adsid
					const adsPage = entry.target.dataset.adsPage
					const adsSection = entry.target.dataset.adsSection

					if (
						entry.isIntersecting &&
						adsId &&
						entry.intersectionRatio > threshold
					) {
						this.postImpression(
							adsId,
							impressionMeta,
							adsPage,
							adsSection
						)
					}
				})
			})
			list.forEach(element => {
				observer.observe(element)
			})
		},
		validateImpressions({
			ads,
			containerElementId,
			listElementClass,
			threshold = 0,
			impressionMeta,
			swiperRefs
		}) {
			const _ads =
				ads && ((ads.ids && ads.ids.length > 0) || ads.length > 0)

			if (!navigator.userAgent.toLowerCase().includes("googlebot")) {
				if ("IntersectionObserver" in window) {
					const element = document.getElementById(containerElementId)

					let observer = new IntersectionObserver(entries => {
						entries.forEach(entry => {
							if (entry.isIntersecting) {
								this.observeList({
									containerElementId,
									listElementClass,
									impressionMeta
								})
								// To handle when swipe the swiper
								if (swiperRefs) {
									swiperRefs.on("slideChange", () => {
										this.observeList({
											containerElementId,
											listElementClass,
											threshold,
											impressionMeta
										})
									})
								}
							}
						})
					})
					observer.observe(element)
				}
			}
		}
	}
}
